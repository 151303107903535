/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import config from '../../config'
import MainLayout from '../layouts'
import PostsList from '../components/PostList'
import PostsListHeader from '../components/PostsListHeader'
import LatestPost from '../components/LatestPost'

const TestPage = ({
  data: {
    allMdx: { nodes: posts },
    latest: { nodes: latestPost },
  },
}) => (
  <MainLayout>
    <Helmet>
      <title>{config.siteTitle}</title>
    </Helmet>
    <LatestPost post={latestPost[0]} />
    <PostsListHeader>All posts</PostsListHeader>
    <PostsList posts={posts} />
  </MainLayout>
)

export default TestPage

TestPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
  }).isRequired,
}

export const TestPageQuery = graphql`
  query TestPageQuery {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, skip: 1) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "MM/DD/YYYY")
          categories
          cover {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 800, maxHeight: 800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        excerpt(pruneLength: 200)
        timeToRead
      }
    }
    latest: allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: 1) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "MM/DD/YYYY")
          categories
          cover {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 1000, maxHeight: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        excerpt(pruneLength: 450)
        timeToRead
      }
    }
  }
`
