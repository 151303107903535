import React from 'react'
import styled from 'styled-components'
import AuthorImg from './author.jpg'

const Author = ({ date, timeToRead, latestPost }) => {
  if (latestPost)
    return (
      <Text>
        created by <span>Mateusz Gajda</span> on <span>{date}</span>
      </Text>
    )
  return (
    <InlineBlock>
      <Round src={AuthorImg} />
      <DetailedText>
        <span>Mateusz Gajda</span>
        {date} - {timeToRead} min read
      </DetailedText>
    </InlineBlock>
  )
}

export default Author

const InlineBlock = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`

const Text = styled.p`
  margin: 0;
  color: ${props => props.theme.colors.grey.ultraLight};
  font-weight: 500;
  font-size: 0.9rem;

  span {
    color: ${props => props.theme.colors.grey.default};
  }
`
const DetailedText = styled(Text)`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
`

const Round = styled.img`
  margin: 0;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  background-image: url(../../../assets/author.png);
  /* background-color: ${props => props.theme.colors.grey.ultraLight}; */
`
