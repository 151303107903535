import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Categories from '../Categories'
import Author from '../Author'

const LatestPost = ({ post }) => {
  const {
    excerpt,
    fields: { slug },
    frontmatter: {
      title,
      date,
      categories,
      cover: {
        children: [{ fluid }],
      },
    },
  } = post
  return (
    <Wrapper>
      <ImageWrapper style={{position:'relative'}}>
        <div>
          <h1>Latest</h1>
        </div>
        <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
          <Image to={slug} style={{ backgroundImage: `url(${fluid.src})` }} />
        </div>
      </ImageWrapper>
      <ContentWrapper>
        <Header>
          <Categories categories={categories} />
          <h2>
            <Link to={slug}>{title}</Link>
          </h2>
          <Author date={date} latestPost />
        </Header>
        <p>{excerpt}</p>
      </ContentWrapper>
    </Wrapper>
  )
}

LatestPost.propTypes = {
  post: PropTypes.object.isRequired,
}

export default LatestPost

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 1rem;
`
const ImageWrapper = styled.div`
  flex-grow: 1;

  h1 {
    position: absolute;
    background-color: ${props => props.theme.colors.bg};
    padding: 0.2rem 1rem;
    font-family: ${props => props.theme.fontFamily.serif};
    font-weight: 800;
    left: -2rem;

    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      padding: 0.2rem 1rem;
      left: 0rem;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    left: -0.1rem;
  }
`

const Image = styled(Link)`
  width: 100%;
  border-radius: 1.111rem;
  background: 50% no-repeat;
  background-size: cover;
  height: 25rem;
  padding: 0 1rem;
`

const ContentWrapper = styled.div`
  width: 45%;
  padding: 0 1rem 0 1.77rem;
  & > p {
    font-size: 1.111rem;
    line-height: 1.444rem;
    margin: 1.5rem 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    width: 100%;
    padding: 1rem 0 0 0;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0;

  h2 {
    margin: 0.3rem 0;
  }
`
